<!-- 打开小程序的中转页 -->
<template>
  <div class="main">
    <div class="top">
      <p>当前UA：{{ userAgent }}</p>
    </div>
    <div class="mid">
      <p>是否是微信：{{ isWechat }}</p>
      <p>是否是小程序：{{ isWechatMp }}</p>
    </div>
    <div class="mid">
      <p>帖子链接：</p>
      <p>{{ thread_url }}</p>
      <p class="mt">课程链接：</p>
      <p>{{ lesson_url }}</p>
    </div>
    <div class="menus">
      <!-- <span class="button" @click="openMiniProgram(1)">用小程序打开帖子</span>
      <span class="button mt" @click="openMiniProgram(2)">用小程序打开课程</span> -->
    </div>

    <div>
      <AiAnalysis :config="aiConfig" answer-type="1,2,3" @success="analysisSuccess(item)" @error="(t,e)=>analysisError(t,e)" />
    </div>

    <div v-if="openPagePath" style="display: flex;align-items: center;justify-content: center; width: 100%;">
      <wx-open-launch-weapp
        id="launch-btn"
        :appid="appid"
        :path="openPagePath"
      >
        <script type="text/wxtag-template">
          <button
          style="width: 200px; height: 45px; text-align: center;
          font-size: 17px; display: block; margin: 0 auto; padding: 8px 24px;
          border: none; border-radius: 4px; background-color: #ee2e2e; color:#fff;"
          >打开小程序</button>
        </script>
        <!-- <template>
          <button
            style="width: 100%; height: 45px; text-align: center;
          font-size: 17px; display: block; margin: 0 auto; padding: 8px 24px;
          border: none; border-radius: 4px; background-color: #07c160; color:#fff;"
          >打开小程序</button>
        </template> -->
        <!-- <script type="text/wxtag-template">

          <button >打开小程序</button>
        </script> -->
        <!-- <script type="text/wxtag-template">
          <style>.btn { display: inline-block;margin-left: 50px;width: 100%;height: 70px;background-color: #ee2e2e;
          line-height: 70px;text-align: center;color: #fff;border-radius: 16px; }</style>
          <button class="btn">打开小1程序</button>
        </script> -->
      </wx-open-launch-weapp>
    </div>
  </div>
</template>

<!-- <script src="https://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script> -->
<script>
import WechatState from '@/utils/isWechatState.js'
import { getWxUrl } from '@/utils/index.js'
import { getShares } from '@/api/infohome.js'
import { getAiEnToken } from '@/api/chatGPT'

export default {
  data() {
    return {
      userAgent: '',
      aloading: false,
      isWechat: WechatState.isWechat,
      isWechatMp: WechatState.isWechatMiniprogram,
      thread_url: 'https://m.zhulong.com/bbs/d/50323824.html',
      lesson_url: 'https://m.zhulong.com/edu/d/20692.html',
      appid: 'wx828b87bfa158b387',
      pagepath: 'pages/webIndex/webIndex', // pages前不要/
      openPagePath: '',
      // ai请求的配置信息
      aiConfig: {
        appkey: '',
        token: ''
      }
    }
  },
  created() {
    this.userAgent = window.navigator.userAgent

    // 获取配置config
    this.getAiEnToken()

    // var btn = document.getElementById('launch-btn')
    // btn.addEventListener('launch', function(e) {
    //   console.log('success')
    // })
    // btn.addEventListener('error', function(e) {
    //   console.log('fail', e.detail)
    // })

    if (this.isWechat) {
      if (!this.isWechatMp) {
        this.openPagePath = this.pagepath + '?share=' + this.lesson_url
        this.getWxConfigInfo()
      }
    }
  },
  mounted() {
    console.log('11111')
  },
  methods: {
    openMiniProgram(val) {
    // weixin://dl/business/?appid=*APPID*&path=*PATH*&query=*QUERY*&env_version=*ENV_VERSION*
      const oepn_url = val === 1 ? this.thread_url : this.lesson_url
      const query = 'share=' + oepn_url
      const scheme = `weixin://dl/business/?appid=${this.appid}&path=${this.pagepath}&query=${query}&env_version=release`
      if (this.isWechat) {
        if (!this.isWechatMp) {
          // 微信 走 接口
          this.$toast('微信 h5 打开小程序')
          this.getOpenMpUrl(oepn_url)
        } else {
          this.$toast('小程序 内 h5 打开小程序其他页面')
          const path = `/pages/webIndex/webIndex?share=${oepn_url}`
          this.$wx.miniProgram.navigateTo({
            url: path
          })
        }
      } else {
        // h5
        this.$toast(scheme)
        window.location.href = scheme
      }
    },
    // 获取链接
    getOpenMpUrl(url) {
      this.getWxConfigInfo()
      // getWxUrl({
      //   'path': this.pagepath,
      //   'query': 'share=' + url,
      //   'env_version': 'release',
      //   'auth_app_id': this.appid,
      //   'type': 1
      // }).then(res => {
      //   if (res.result) {
      //     const mpurl = res.result
      //     this.$toast(mpurl)
      //     window.location.href = mpurl
      //   }
      // })
    },
    // 获取配置
    getWxConfigInfo() {
      this.$toast('请求配置')
      var info = {
        url: window.location.href.split('#')[0]
      }
      getShares(info).then(res => {
        console.log(res)
        const wxconf = res.result.wxconf
        console.log(wxconf)
        this.configNext(wxconf)
        // const result = res.result ? res.result : {}
        // this.shareArr = result.shares ? result.shares : []
        // const wxconf = result.wxconf ? result.wxconf : {}
        // this.shareWxConfig = wxconf
        // this.confiShareWx()
      })
    },
    configNext(data) {
      console.log(data)
      const wx = this.$wx
      this.$toast('获取到配置')
      const _this = this
      wx.config({
        appId: data.appId,
        // 必填，生成签名的时间戳
        timestamp: data.timestamp,
        // 必填，生成签名的随机串
        nonceStr: data.nonceStr,
        // 必填，签名
        signature: data.signature,
        // 必填，需要使用的JS接口列表，且任意填写
        jsApiList: ['scanQRCode'],
        // 可选，需要使用的开放标签列表，wx-open-launch-weapp 指H5跳转小程序 wx-open-launch-app 指H5跳转app
        openTagList: ['wx-open-launch-weapp']
      })
      wx.ready(function() {
        _this.$toast('ready')
        var btn = document.getElementById('launch-btn')
        // launch 用户点击跳转按钮并对确认弹窗进行操作后触发
        btn.addEventListener('launch', function(e) {
          console.log(e, 'success')
          _this.$toast('click open')
        })
        // error 用户点击跳转按钮后出现错误
        btn.addEventListener('error', function(e) {
          console.log(e.detail, 'fail')
          _this.$toast('click 错误 = ' + e.detail)
        })
      })
      wx.error(function(res) {
        console.log(res, 'error')
        _this.$toast('错误 = ' + res)
      })

      // wx.config({
      // 			// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
      // 			// debug: true,
      // 			// 必填，公众号的唯一标识
      // 			appId: data.appId,
      // 			// 必填，生成签名的时间戳
      // 			timestamp: data.data.timestamp,
      // 			// 必填，生成签名的随机串
      // 			nonceStr: data.data.nonceStr,
      // 			// 必填，签名
      // 			signature: data.data.signature,
      // 			// 必填，需要使用的JS接口列表，且任意填写
      // 			jsApiList: ['scanQRCode'],
      // 			// 可选，需要使用的开放标签列表，wx-open-launch-weapp 指H5跳转小程序 wx-open-launch-app 指H5跳转app
      // 			openTagList: ["wx-open-launch-weapp"],
      //       wx.ready(function () {
      // 			var btn = document.getElementById('launch-btn');
      // 			// launch 用户点击跳转按钮并对确认弹窗进行操作后触发
      // 			btn.addEventListener('launch', function (e) {
      // 				console.log(e,'success');
      // 			});
      // 			// error 用户点击跳转按钮后出现错误
      // 			btn.addEventListener('error', function (e) {
      // 				console.log(e.detail,'fail');
      // 			});
      // 		});
      // 		wx.error(function (res) {
      // 			console.log(res, 'error');
      // 			// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
      // 		});

      //   })
    },
    // 回调，结果：成功
    analysisSuccess(text) {
      console.log('答案变化 = ', text)
    },
    // 回调，结果：失败
    analysisError(type, tips) {
      if (type == 1) {
        console.log('图片 识别出错 = ', tips)
      } else {
        console.log('语音 识别出错 = ', tips)
      }
      this.$toast(tips)
    },
    // 获取token
    getAiEnToken() {
      getAiEnToken().then((res) => {
        if (res.errNo == 0) {
          var data = JSON.parse(res.result)
          this.aiConfig.appkey = data.appkey || ''
          this.aiConfig.token = data.token || ''
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>

.main {
  padding: 40px 30px;
  font-family: PingFangSC-Medium;
  font-size: 26px;
  color: #666;
  line-height: 40px;
  font-weight: 600;
}
.top {
  margin-bottom: 40px;
}
.mid {
  margin-bottom: 40px;
}
.mt {
  margin-top: 30px;
}
.menus {
  margin-top: 80px;
}
.button {
  display: inline-block;
  margin-left: 50px;
  width: calc(100% - 100px);
  height: 70px;
  background-color: #ee2e2e;
  line-height: 70px;
  text-align: center;
  color: #fff;
  border-radius: 16px;

}
.use_camera_box {
  background-color: antiquewhite;
  .fileinput-button {
      position: relative;
      display: inline-block;
      overflow: hidden;
  }
  .fileinput-button input{
    position:absolute;
    right: 0px;
    top: 0px;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    font-size: 200px;
  }

}
.use_microphone_box {
  margin-top: 30px;
  background-color: lightpink;
  .speech_menus {
    background-color: lightblue;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.use_camera_box, .use_microphone_box {
  padding: 1px 0px 10px;
  .tip_p {
    margin: 30px 0 10px;
  }
  .capture_btn {
    padding: 10px 30px 8px;
    border:  2px solid #ee2e2e;
    color: #ee2e2e;
    border-radius: 40px;
    line-height: 30px;
    background-color: #fff;
  }
}
</style>
